import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { format, parseISO } from "date-fns";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Templates/Hero";
import Image from "../components/Global/Image";
import Link from "../components/Global/Link";
import Pagination from "../components/Global/Pagination";

// ====

function trimSlash(path) {
  return path.replace(/\/$/, "");
}

const News = ({ blok, data, pageContext, location }) => {
  let { numPages, limit, skip, currentPage, homepage, parent_id } =
    pageContext || 0;

  let doc;
  let parent;
  let pageSlug;
  let items;
  if (data) {
    //    doc = JSON.parse(data.index.content);
    doc = JSON.parse(pageContext.story.content);
    pageSlug = trimSlash(pageContext.story.full_slug);
    items = data.news;
    parent = data.index;
  } else {
    doc = blok;
    parent = blok;
    items = {
      nodes: [
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
      ],
    };
  }
  const pageTitle = homepage ? doc.title : doc.pagination_title;

  const openGraph = {
    type: "website",
    title: `${pageTitle}`,
    description: `${homepage ? doc.body : doc.pagination_body}`,
    image: doc.hero,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      <Hero
        title={doc.title && doc.title}
        body={doc.body && doc.body}
        background={doc.hero?.filename}
        colour={doc.colour && doc.colour}
        fixed
      />
      <section className="w-100 brand-background-white brand-text-black brand-background-clip">
        <div className="container py-5">
          <div className="row gy-4">
            {items &&
              items.nodes.map((node, index) => {
                const news = JSON.parse(node.content);
                let size = "3";

                if (homepage && index === 0) {
                  size = "6";
                }
                if (
                  (homepage && index === 9) ||
                  (homepage && index === 10) ||
                  (homepage && index === 11)
                ) {
                  size = "6";
                }

                return (
                  <div className={`col-12 col-lg-${size}`}>
                    <div className="d-flex flex-column brand-background-white brand-text-black position-relative">
                      <Image
                        src={news.image?.filename}
                        sm="960x540"
                        md="960x540"
                        lg="1920x1080"
                        alt={news.image?.alt}
                        className="brand-border-radius mb-4 w-100"
                      />
                      <p className="brand-text-red brand-text-size-14">
                        {format(parseISO(news.date), "do MMMM yyyy")}
                      </p>
                      <h4 className="brand-font brand-text-black">
                        {news.title}
                      </h4>
                      <p className="brand-text-black">{news.preview}</p>
                      <Link to={node.full_slug} stretched />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      {numPages && numPages > 1 && (
        <Pagination
          numPages={numPages}
          currentPage={currentPage}
          paginationPrefix={doc.pagination_prefix}
          homepage={pageSlug}
        />
      )}
    </Layout>
  );
};

export default News;

export const QUERY = graphql`
  query NewsIndexDefaultQuery($skip: Int!, $limit: Int!, $parent_id: [Int]) {
    # Query the data from the index page
    index: storyblokEntry(
      field_component: { eq: "news" }
      lang: { eq: "default" }
    ) {
      content
    }

    # Grab news articles
    news: allStoryblokEntry(
      filter: {
        field_component: { eq: "news_post" }
        parent_id: { in: $parent_id }
      }
      sort: {
        fields: [field_featured_boolean, field_date_string]
        order: [DESC, DESC]
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        full_slug
        field_date_string
        content
        parent_id
      }
    }
  }
`;

News.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blok: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

News.defaultProps = {
  pageContext: null,
  blok: null,
  data: null,
};
